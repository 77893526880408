/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Form, Button, Col, InputGroup, Spinner } from 'react-bootstrap'
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSelector } from 'react-redux'

import { isDepositPayment } from '../../utils/payment'
import toast from '../toast'
import { DOCUMENT_TYPE } from '../../utils/constant'
import useApi from '../../api/useApi'
import { formatAmount } from '../../utils/currency'

const createOptions = () => {
  return {
    showIcon: true,
    style: {
      base: {
        fontSize: '14px',
        color: '#171E23',
        '::placeholder': {
          color: '#696969',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  }
}

const PayWithCardComponent = ({ handleResult, stripe, elements }) => {
  const api = useApi()
  const { record } = useSelector(store => {
    const { main } = store
    return main
  })

  const [tip, setTip] = useState('')
  const [tipCustom, setTipCustom] = useState('')
  const [isPayAmount, setPayAmount] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [completeCard, setCompleteCard] = useState({
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
  })

  const { payment, total, metadata } = record
  const { document } = metadata
  const { deposit = 0, amount = 0 } = payment
  const isDeposit = isDepositPayment(record)

  const yupObject = {
    name: Yup.string().required(),
    zipCode: Yup.string().required(),
  }

  if (isPayAmount) yupObject.amount = Yup.string().required()

  const schema = Yup.object(yupObject)

  const [data, setData] = useState({
    errorMessage: '',
    clientSecret: '',
  })

  const handleTipCustom = e => {
    const { value } = e.target
    setTipCustom(value)
  }

  const handleSubmit = async values => {
    const { name, zipCode } = values
    let balanceDue = isPayAmount ? values.amount * 100 : total - amount
    if (document === DOCUMENT_TYPE.ESTIMATE) balanceDue = deposit
    setLoading(true)
    const cardElement = elements.getElement(CardNumberElement)

    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
        address: {
          postal_code: zipCode,
        },
      },
    })

    if (error) {
      setLoading(false)
      return false
    }

    const tipAmount = tip ? tipAmountWithPercent(tip) : tipCustom * 100
    const payload = {
      id: record._id,
      amount: Math.ceil(balanceDue),
      tipAmount: Math.ceil(tipAmount),
    }

    api.payment
      .createPayment(payload)
      .then(res => {
        const { clientSecret } = res.data
        stripe
          .confirmCardPayment(clientSecret, {
            payment_method: { card: cardElement },
          })
          .then(function (result) {
            console.log('result: ', result)
            if (result.error) {
              throw Error('payment fail!')
            } else {
              const data = {
                ...res.data,
                amount: Math.ceil(balanceDue),
                tipAmount: Math.ceil(tipAmount),
              }
              handleResult(data)
            }
          })
      })
      .catch(() => {
        toast.error({
          title: 'Payment Fail',
          body: `Please contact with MTRX's Admin via phone (1-888-626-6750)`,
        })
        setLoading(false)
      })
  }

  const formik = useFormik({
    initialValues: {
      name: '',
      zipCode: '',
      amount: '',
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors, isValid, dirty } = formik

  const isValidform =
    isValid &&
    dirty &&
    Object.keys(completeCard).every(key => completeCard[key])

  const tipAmountWithPercent = percent => {
    let balanceDue = isPayAmount ? values.amount * 100 : total - amount
    if (document === DOCUMENT_TYPE.ESTIMATE) balanceDue = deposit
    return (percent / 100) * balanceDue
  }

  useEffect(() => {
    const validateFormik = async () => {
      await formik.validateForm()
    }
    validateFormik()
  }, [isPayAmount])

  const cardOnChange = ({ elementType, complete }) => {
    setCompleteCard({
      ...completeCard,
      [elementType]: complete,
    })
  }

  const tipAmount = tip ? tipAmountWithPercent(tip) : tipCustom * 100
  let payAmount = 0
  if (isDeposit) payAmount = deposit
  if (!isDeposit && !isPayAmount) payAmount = total - amount
  if (!isDeposit && isPayAmount) payAmount = (values.amount || 0) * 100

  return (
    <Form
      onSubmit={e => {
        e.preventDefault()
        formik.handleSubmit(e)
      }}
    >
      <div className='text-danger'>{data.errorMessage}</div>
      <Form.Row>
        {isPayAmount && (
          <Form.Group as={Col} md='12'>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type='number'
              name='amount'
              placeholder='Enter $ payment amount'
              value={values.amount}
              onChange={formik.handleChange}
              isInvalid={!!(touched.amount && errors.amount)}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} md='12'>
          <Form.Label>Name on card</Form.Label>

          <Form.Control
            type='text'
            name='name'
            placeholder='Type the Name on card here'
            value={values.name}
            onChange={formik.handleChange}
            isInvalid={!!(touched.name && errors.name)}
          />
        </Form.Group>
        <Form.Group as={Col} md='12'>
          <Form.Label>Card details</Form.Label>
          <div className='bg-grey'>
            <div className='split-form'>
              <CardNumberElement
                options={{ ...createOptions() }}
                {...createOptions()}
                className='form-control decor-cards'
                onChange={cardOnChange}
              />
            </div>
            <div className='split-form'>
              <InputGroup>
                <Form.Group className='w-50 mb-0'>
                  <CardExpiryElement
                    {...createOptions()}
                    onChange={cardOnChange}
                    className='form-control decord-expiry'
                  />
                </Form.Group>
                <Form.Group className='w-50 mb-0 position-relative group-cvc'>
                  <CardCvcElement
                    {...createOptions()}
                    onChange={cardOnChange}
                    className='form-control decor-cvc'
                  />
                  <img src='/assets/images/icons/cvc.svg' alt='' />
                </Form.Group>
              </InputGroup>
            </div>
          </div>
        </Form.Group>

        <Form.Group as={Col} md='12'>
          <Form.Label>Zip Code</Form.Label>
          <Form.Control
            type='text'
            name='zipCode'
            placeholder='Enter zip code'
            value={values.zipCode}
            onChange={formik.handleChange}
            isInvalid={!!(touched.zipCode && errors.zipCode)}
          />
        </Form.Group>
        {/* tip options */}
        {!isDeposit && (
          <>
            <Form.Group as={Col} md='12'>
              <Form.Label>Tips</Form.Label>
              <>
                <div className='w-100 tip d-flex flex-wrap justify-content-between'>
                  <div
                    role='button'
                    className='tip-block border rounded d-flex pl-3 pr-3 mb-2'
                    onClick={() => setTip(25)}
                  >
                    <div className='flex-grow-1 font-875 font-weight-bold'>
                      25%
                    <br />
                      <div className='tip-price font-weight-normal'>
                        {`$${formatAmount(tipAmountWithPercent(25) / 100)}`}
                      </div>
                    </div>
                    <img
                      className='icon'
                      src={`/assets/images/icons/${tip === 25 ? 'checked' : 'uncheck'
                        }.svg`}
                      alt=''
                    />
                  </div>

                  <div
                    role='button'
                    className='tip-block border rounded d-flex pl-3 pr-3 mb-2'
                    onClick={() => setTip(20)}
                  >
                    <div className='flex-grow-1 font-875 font-weight-bold'>
                      20%
                    <br />
                      <div className='tip-price font-weight-normal'>
                        {`$${formatAmount(tipAmountWithPercent(20) / 100)}`}
                      </div>
                    </div>
                    <img
                      className='icon'
                      src={`/assets/images/icons/${tip === 20 ? 'checked' : 'uncheck'
                        }.svg`}
                      alt=''
                    />
                  </div>

                  <div
                    role='button'
                    className='tip-block border rounded d-flex pl-3 pr-3 mb-2'
                    onClick={() => setTip(15)}
                  >
                    <div className='flex-grow-1 font-875 font-weight-bold'>
                      15%
                    <br />
                      <div className='tip-price font-weight-normal'>
                        {`$${formatAmount(tipAmountWithPercent(15) / 100)}`}
                      </div>
                    </div>
                    <img
                      className='icon'
                      src={`/assets/images/icons/${tip === 15 ? 'checked' : 'uncheck'
                        }.svg`}
                      alt=''
                    />
                  </div>

                  <div
                    role='button'
                    onClick={() => setTip('')}
                    className={`tip-block ${!tip && 'active'
                      } border rounded d-flex mb-2 font-875 justify-content-center align-items-center`}
                  >
                    None
                </div>
                </div>
              </>
            </Form.Group>

            {/* custom form */}
            {!tip && (
              <Form.Group as={Col} md='12'>
                <Form.Label>Custom Tip</Form.Label>
                <Form.Control
                  type='number'
                  name='name'
                  placeholder='Enter $ tip'
                  defaultValue={tipCustom}
                  onChange={handleTipCustom}
                />
              </Form.Group>
            )}
            {tipAmount > 0 && (
              <div className='text-center w-100 pb-2'>
                <div className='font-weight-bold h4'>
                  {`$${formatAmount((payAmount + tipAmount) / 100)}`}
                </div>
                <div className='h6'>
                  {`$${formatAmount(payAmount / 100)} Payment + $${formatAmount(
                    tipAmount / 100
                  )} Tip`}
                </div>
              </div>
            )}
          </>
        )}


      </Form.Row>
      <Button
        type='submit'
        disabled={isLoading || !isValidform}
        className='btn-block btn-pay-card'
        variant={`${isLoading || !isValidform ? 'secondary' : 'primary'}`}
      >
        {isLoading && (
          <Spinner
            className='mr-1'
            as='span'
            animation='border'
            size='sm'
            role='status'
            aria-hidden='true'
          />
        )}
        {!isDeposit && isPayAmount
          ? `PAY`
          : `PAY $${formatAmount((payAmount + tipAmount) / 100)}`}
      </Button>
      {!isDeposit && (
        < div
          className='text-primary pay-different-amount'
          role='button'
          onClick={() => setPayAmount(!isPayAmount)}
        >
          {isPayAmount ? 'PAY FULL BALANCE DUE' : 'PAY A DIFFERENT AMOUNT'}
          <img
            className='ml-1'
            src={`/assets/images/icons/${isPayAmount ? 'check-retangle.svg' : 'switch-card.svg'}`}
            alt=''
          />
        </div>
      )
      }
    </Form >
  )
}

export default PayWithCardComponent
