import { DOCUMENT_TYPE } from './constant'

export const isNoDepositPayment = record => {
  const { payment, metadata } = record
  const { deposit = 0 } = payment
  return deposit <= 0 && metadata.document === DOCUMENT_TYPE.ESTIMATE
}

export const isDepositPayment = record => {
  const { payment, total, metadata } = record
  const { deposit = 0, amount = 0 } = payment
  return (
    deposit > 0 &&
    deposit > amount &&
    total > 0 &&
    metadata.document === DOCUMENT_TYPE.ESTIMATE
  )
}

export const isDepositPartialPayment = record => {
  const { payment, metadata } = record
  const { deposit = 0, amount } = payment
  return deposit < amount && metadata.document === DOCUMENT_TYPE.ESTIMATE
}

export const isDepositPaidPayment = record => {
  const { payment, metadata } = record
  const { deposit = 0, amount = 0 } = payment
  return (
    deposit > 0 &&
    deposit <= amount &&
    metadata.document === DOCUMENT_TYPE.ESTIMATE
  )
}

export const isPaidPayment = record => {
  const { payment, metadata } = record
  const { status } = payment
  return status === 2 && metadata.document === DOCUMENT_TYPE.INVOICE
}

export const isPartialPaidPayment = record => {
  const { payment, metadata } = record
  const { status } = payment
  return status === 1 && metadata.document === DOCUMENT_TYPE.INVOICE
}

export const isUnpaidPayment = record => {
  const { payment, metadata } = record
  const { status } = payment
  return status === 0 && metadata.document === DOCUMENT_TYPE.INVOICE
}
