import React, { useState } from 'react'
import { Image, Button } from 'react-bootstrap'
import { Box } from 'reflexbox'

export default ({ setPhotoModal, downloadAll, vehicleData, photoImages }) => {
  const [isPhoto, setPhoto] = useState(false)
  const { photos, vehicle } = vehicleData
  const { info } = vehicle
  const photoLength = photoImages.length
  return (
    <Box className='vehicle-photo'>
      {isPhoto && (
        <>
          <Box className='vehicle-photo-list'>
            <p>Photos</p>
            <div className='photos'>
              {photoImages.map((photo, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='photo'>
                      <Image
                        src={photo.thumbnail}
                        alt={photo.name}
                        role='button'
                        onClick={() =>
                          setPhotoModal({ photo, info, index, photoImages })
                        }
                      />
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </Box>
          <Button
            variant='link'
            className='photo-link'
            onClick={() => downloadAll(photoImages)}
          >
            DOWNLOAD ALL PHOTOS ({photoLength})
            <Image
              className='ml-2'
              src='/assets/images/icons/download-all-photos.svg'
              alt='photo'
            />
          </Button>
        </>
      )}

      {!isPhoto && photoLength > 0 && (
        <Button
          variant='link'
          className='photo-link'
          onClick={() => setPhoto(true)}
        >
          SEE PHOTOS ({photoLength})
          <Image
            className='ml-2'
            src='/assets/images/icons/photo.svg'
            alt='photo'
          />
        </Button>
      )}
      {isPhoto && (
        <Button
          variant='link'
          className='hide-photo'
          onClick={() => setPhoto(false)}
        >
          CLOSE PHOTOS ({photoLength})
        </Button>
      )}
    </Box>
  )
}
