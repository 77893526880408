import React from 'react'
import { Button, Col, Form, Image } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get } from 'lodash'

import { formatDate, formatDateTime } from '../../utils/dateTime'
import { isDepositPayment } from '../../utils/payment'
import useApi from '../../api/useApi'
import toast from '../toast'
import { formatAmount } from '../../utils/currency'

const Receipt = ({ handleClose, paymentData }) => {
  const api = useApi()
  const { record } = useSelector(store => {
    const { main } = store
    return main
  })
  const { number, payment, total, date, metadata, _id } = record
  const { company } = metadata
  const { deposit = 0 } = payment

  const isDeposit = isDepositPayment(record)

  const schema = Yup.object({
    emailAddress: Yup.string()
      .email('Enter a valid email')
      .required('Email is required'),
    phoneNumber: Yup.string().required(),
  })

  const handleSubmit = async ({ emailAddress, phoneNumber }) => {
    api.payment
      .sendReceipt(_id, {
        payment: paymentData.payment,
        emailAddress,
        phoneNumber,
      })
      .then(() => {
        toast.success({
          title: 'Payment Succesful',
          body: `Your  payment has been successful. A confirmation ${emailAddress} has been sent to ${phoneNumber}.`,
        })
        handleClose(false)
      })
      .catch(() => {
        toast.error({
          title: 'SEND RECEIPT ERROR',
          body: 'Please try again! Thank you.',
        })
      })
  }

  const formik = useFormik({
    initialValues: {
      emailAddress: '',
      phoneNumber: '',
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
  })

  const { values, touched, errors } = formik

  return (
    <div className='container receipt'>
      <div className='row d-lex align-items-center'>
        <div className='col-md-8 invoice-info'>
          <div className='logo-area'>
            <img
              className='invoice-logo'
              src={get(company.business, 'logo', '/assets/images/default.svg')}
              alt='MTRX'
            />
            <div className='h4 font-weight-bold text-capitalize'>
              {company.name}
            </div>
            <div className='h5 font-weight-bold m-0 mt-4'>RECEIPT</div>

            <div className='h5 font-weight-bold pt-4 invoice-total'>
              {isDeposit && (
                <>
                  Estimate Total ${formatAmount(total / 100)}
                  <div className='invoice-number'>
                    Estimate #{number} - {formatDate(date)}
                  </div>
                  <div className='deposit-paid'>
                    ${formatAmount(deposit / 100)} Deposit Paid
                  </div>
                </>
              )}
              {!isDeposit && (
                <>
                  Invoice Total ${formatAmount(total / 100)}
                  <p className='invoice-number'>
                    Invoice #{number} - {formatDate(date)}
                  </p>
                </>
              )}
            </div>
            <div className='payment-verify'>
              <div className='d-flex'>
                <div className='flex-grow-1 font-weight-bold text-left'>
                  Stripe Verified Payment:
                </div>
                <div className='payment-verify-right text-right'>
                  <Image
                    className='mr-2'
                    src='/assets/images/icons/credit-card.svg'
                    alt=''
                  />
                  Credit Card
                </div>
              </div>

              <div className='d-flex'>
                <div className='flex-grow-1 font-weight-bold text-left'>
                  Total Paid:
                </div>
                <div className='payment-verify-right'>
                  {`$${formatAmount(get(paymentData, 'amount', 0) / 100)}`}
                </div>
              </div>

              <div className='d-flex'>
                <div className='flex-grow-1 font-weight-bold text-left'>
                  Date & Time:
                </div>
                <div className='payment-verify-right'>
                  {formatDateTime(paymentData.created)}
                </div>
              </div>
            </div>
          </div>

          <div className='footer-area'>
            <div className='title'>
              <span>
                Powered by <span className='title-point'>Stripe</span>
              </span>
              <div className='font-75'>
                This charge will appear as “Stripe Transfer” on your statement
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-4 '>
          <div className='w-100 text-center'>
            <Button variant='success' className='btn-payment-success'>
              <Image
                className='mr-2'
                src='/assets/images/icons/checked-payment.svg'
                alt=''
              />
              {isDeposit ? 'Deposit Paid' : 'Payment Successful'}
            </Button>
          </div>
          <div className='pay-with-card'>
            <div className='h5 font-weight-bold text-center'>
              How would you like to
              <br />
              receive your digital receipt?
            </div>
            <div className='card-form'>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  formik.handleSubmit(e)
                }}
              >
                <Form.Row>
                  <Form.Group as={Col} md='12'>
                    <Form.Label>Email me</Form.Label>

                    <Form.Control
                      type='text'
                      name='emailAddress'
                      placeholder='Enter email address'
                      value={values.emailAddress}
                      onChange={formik.handleChange}
                      isInvalid={
                        !!(touched.emailAddress && errors.emailAddress)
                      }
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md='12'>
                    <Form.Label>Text me</Form.Label>

                    <Form.Control
                      type='number'
                      name='phoneNumber'
                      value={values.phoneNumber}
                      placeholder='Enter phone number'
                      onChange={formik.handleChange}
                      isInvalid={!!(touched.phoneNumber && errors.phoneNumber)}
                    />
                  </Form.Group>
                </Form.Row>
                <Button type='submit' className='btn-block btn-pay-card'>
                  SEND RECEIPT
                </Button>
                <div
                  className='text-primary pay-different-amount'
                  role='button'
                  onClick={handleClose}
                >
                  NO THANKS
                  <img
                    className='ml-1'
                    src='/assets/images/icons/chevron-right.svg'
                    alt=''
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Receipt
