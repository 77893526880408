import React from 'react'
import { Container, Row, Button, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'reflexbox'
import { get } from 'lodash'

import VehicleDetail from '../vehicleDetail'
import './estimate.scss'
import { formatDate } from '../../utils/dateTime'
import { AMOUNT_TYPE, DOCUMENT_TYPE } from '../../utils/constant'
import { getPdf } from '../../store/actions/main'
import { formatAmount } from '../../utils/currency'

const Estimate = () => {
  const { record } = useSelector(store => {
    const { main } = store
    return main
  })

  const dispatch = useDispatch()

  const {
    client,
    metadata,
    date,
    total,
    number,
    taxAmount,
    discountAmount,
    vehicles,
    accounting,
    _id,
  } = record
  const { document } = metadata
  const { discount, tax } = accounting
  const isShowTax = tax && tax.length > 0 && tax[0].active && tax[0].value > 0
  const isShowDiscount =
    discount &&
    discount.length > 0 &&
    discount[0].active &&
    discount[0].value > 0 &&
    discount[0].discountType

  const subTotal =
    vehicles.length > 0
      ? vehicles.reduce((a, b) => ({ total: a.total + b.total })).total
      : 0
  const taxNumber = isShowTax ? tax[0].value * 100 : 0
  const firstName = get(metadata, 'createdBy.firstName', '')
  const lastName = get(metadata, 'createdBy.lastName', '')
  let fullName = `${firstName} ${lastName}`
  return (
    <Container className='estimate'>
      <div className='e-header'>
        <div className='e-header-content'>
          <Flex className='e-header-flex'>
            <Box className='box1'>
              <h4 className='font-weight-bold text-capitalize'>
                {DOCUMENT_TYPE[document.toUpperCase()]}
              </h4>
              <Button
                variant='link'
                className='text-left p-0 font-75 font-weight-bold'
                onClick={async () => {
                  dispatch(getPdf({ recordId: _id }))
                }}
              >
                VIEW PDF
                <Image
                  className='ml-1'
                  src='/assets/images/icons/pdf.svg'
                  alt=''
                />
              </Button>
            </Box>
            <Box className='border-vertical' />
            <Box className='box2'>
              <h6 className='text-capitalize m-0'>
                {DOCUMENT_TYPE[document.toUpperCase()]} Total
              </h6>
              <h5>${formatAmount(total / 100)}</h5>
              <div className='amount-info'>
                {isShowTax && (
                  <div className='font-875 text-secondary'>
                    Tax ({taxNumber}%): ${formatAmount(taxAmount / 100)}
                  </div>
                )}
                {isShowDiscount && (
                  <div className='font-875 text-secondary'>
                    {subTotal > 0 &&
                      `Subtotal: $${formatAmount(subTotal / 100)} | `}
                    Discount{' '}
                    {discount[0].discountType === AMOUNT_TYPE.PERCENT &&
                      `(${discount[0].value * 100}%) : `}
                    ${formatAmount(discountAmount / 100)}
                  </div>
                )}
              </div>
            </Box>
            <Box className='box3'>
              <h6 className='font-weight-bold m-0'>
                #{number} · {formatDate(date)}
              </h6>
              {fullName.trim() && (
                <div className='font-875'>{`Created by: ${fullName}`}</div>
              )}
              <div className='font-875'>
                for: {client.firstName} {client.lastName}
              </div>
            </Box>
          </Flex>
        </div>
      </div>
      <Row className='vehicle-detail-row'>
        <VehicleDetail />
      </Row>
    </Container>
  )
}

export default Estimate
