import React from 'react'
import { get } from 'lodash'
import { Image, Modal, Col, Row, Carousel } from 'react-bootstrap'

import { DOCUMENT_TYPE } from '../../utils/constant'
import { downloadImage } from '../../utils/image'

import './carouselVehical.scss'

const showPhotoModal = ({ show, setShow, company, document, downloadAll }) => {
  const { photo, info, photoImages } = show
  const address = get(company.business, 'address.lines', []).filter(line => {
    const lineStr = line || ''
    return lineStr.trim() !== ''
  })
  const city = get(company.business, 'address.city', '')
  const state = get(company.business, 'address.state', '')
  const zip = get(company.business, 'address.zip', '')
  const showAddress = [...address, city, state, zip].join(', ')
  const totalPhotos = photoImages.length
  return (
    <Modal
      show={!!show}
      onHide={() => setShow(false)}
      dialogClassName='show-photos-carousel'
      aria-labelledby='example-custom-modal-styling-title'
    >
      <Modal.Body>
        <div className='company-info'>
          <Row className='align-items-center'>
            <Col md={3}>
              <div className='company-info-logo'>
                <Image
                  className='w-100'
                  src='/assets/images/test/car1.svg'
                  alt=''
                />
              </div>
            </Col>
            <Col md={5} className='company-contact'>
              <p className='h4 font-weight-bold'>{company.name}</p>
              {get(company, 'business.phone', '') && (
                <div>
                  <Image src='/assets/images/icons/phone.svg' alt='' />
                  <span className='contact-des'>
                    {get(company, 'business.phone', '')}
                  </span>
                </div>
              )}

              {get(company, 'business.email', '') && (
                <div>
                  <Image src='/assets/images/icons/email.svg' alt='' />
                  <span className='contact-des'>
                    {get(company, 'business.email', '')}
                  </span>
                </div>
              )}

              {address.length > 0 && (
                <div className='contact'>
                  <Image src='/assets/images/icons/location.svg' alt='' />
                  <span className='contact-des'>{showAddress}</span>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className='estimate-carousel'>
          <Carousel
            activeIndex={show.index}
            onSelect={selectedIndex => {
              setShow({ ...show, index: selectedIndex })
            }}
          >
            {photoImages.map((el, i) => {
              return (
                <Carousel.Item key={i.toString()}>
                  <img
                    className='d-block w-100 main-img'
                    src={el.main}
                    alt={el.name}
                  />
                  <Carousel.Caption>
                    <h3>{`${el.isBefore ? 'Before Front' : ' After Trunk'} ${
                      el.name
                    }`}</h3>
                    <p>
                      <span className='font-weight-bold'>
                        {info.year} {info.make}
                      </span>{' '}
                      | Model: {info.model}.
                    </p>
                    <p className='text-secondary'>
                      {show.index + 1} of {totalPhotos} photos
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
              )
            })}
          </Carousel>
          <div className='control-carousel'>
            <div
              role='button'
              onClick={() => {
                downloadAll(photoImages)
              }}
              className='text-center photo-link font-weight-bold text-primary'
            >
              DOWNLOAD ALL PHOTOS
              <Image
                className='ml-2'
                src='/assets/images/icons/download-photos.svg'
                alt='photo'
              />
            </div>
            <button
              type='button'
              className='btn-download-photos btn btn-primary font-weight-bold'
              onClick={() => {
                downloadImage(photo.main)
              }}
            >
              download this photo
            </button>
          </div>
        </div>
        <div className='back-link'>
          <div
            role='button'
            className='font-75 photo-link font-weight-bold text-uppercase text-primary mt-3'
            onClick={() => setShow(false)}
          >
            Back to {DOCUMENT_TYPE[document.toUpperCase()]}
          </div>
        </div>
        <footer className='footer'>
          <Image src='/assets/images/footerlogo.svg' />
          <div className='text-center font-75'>
            Powered by Mobile Tech RX
            <br />
            Mobitech International Inc. | © 2020
          </div>
        </footer>
      </Modal.Body>
    </Modal>
  )
}
export default showPhotoModal
